import React, { createContext, useContext, useEffect, useState } from 'react';
import { IKlass } from '../types/IKlass';
import { apiRequest } from '../utils/apiRequest';
import { globalEndpoints } from '../globals/globalEndpoints';

interface IKlassContext {
    klassCode: string | null;
    setKlassCode: (code: string | null) => void;
    klass: IKlass | null;
    setKlass: React.Dispatch<React.SetStateAction<IKlass | null>>;
    klassLoading: boolean;
    setKlassLoading: React.Dispatch<React.SetStateAction<boolean>>;
    klassError: string | null;
    setKlassError: React.Dispatch<React.SetStateAction<string | null>>;
}

const KlassContext = createContext<IKlassContext>({
    klassCode: null,
    setKlassCode: () => {},
    klass: null,
    setKlass: () => {},
    klassLoading: false,
    setKlassLoading: () => {},
    klassError: null,
    setKlassError: () => {}
});

export const useKlassContext = () => {
    const { klassCode, setKlassCode, klass, setKlass, klassLoading, setKlassLoading, klassError, setKlassError } = useContext(KlassContext)
    return { klassCode, setKlassCode, klass, setKlass, klassLoading, setKlassLoading, klassError, setKlassError }
};

const KlassContextProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const [klassCodeInternal, setKlassCodeInternal] = useState<string | null>(null)
    const [klass, setKlass] = useState<IKlass | null>(null)
    const [klassLoading, setKlassLoading] = useState<boolean>(true)
    const [klassError, setKlassError] = useState<string | null>(null)

    const setKlassCode = (code: string | null) => {
        if (typeof code === 'string') {
            setKlassCodeInternal(code.toLowerCase().trim())
            return
        }
        setKlassCodeInternal(code)
    }

    useEffect(() => {
        setKlassLoading(true)
        setKlassError(null)
        // Skip request if no klass code exists
        if (klassCodeInternal === null) {
            setKlass(null)
            setKlassLoading(false)
            return
        }
        // Skip request if klass exists and klass code matches
        if (klass && klassCodeInternal === klass?.klass_code_lowercase) {
            setKlassLoading(false)
            return
        }
        // Make a request to get the klass using the code
        apiRequest(globalEndpoints?.klasses?.klassCode(klassCodeInternal), 'GET')
        .then(async (res) => {
            if (res.ok) {
                const data = await res.json()
                setKlass(data)
                setKlassLoading(false)
                return
            }
            // Raise an error if the response is not ok, which will be picked up in the .catch block
            const error = await res.json()
            throw new Error(error?.message ? error?.message : 'We could not find your class code')
        })
        .catch((error) => {
            setKlassLoading(false)
            setKlassError(error?.message)
        })
    }, [klassCodeInternal])

    return (
        <KlassContext.Provider value={{ klassCode: klassCodeInternal, setKlassCode, klass, setKlass, klassLoading, setKlassLoading, klassError, setKlassError }}>
            {children}
        </KlassContext.Provider>
    );
};

export default KlassContextProvider;
